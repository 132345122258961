import React ,{Fragment, useEffect, useState} from 'react'
import { connect } from 'react-redux'
import Headershape from '../components/Headershape'
import Logo from '../components/Logo'
import Navigation from '../components/Navigation'
import {withRouter } from 'react-router-dom'
import { setThankyou } from '../actions/global-actions'

const ThankMessage = ({history, setThankyou}) => {

	useEffect(() => {
		setTimeout (()=>{
			history.push('/overzicht')
		},4000)
		return () => {
			setThankyou(false)
		}
	}, [])

	return (
		<Fragment>
			<Headershape/>
			<main>
				<section className="page">
					<aside>
						<Logo />
						<Navigation/>
					</aside>
					<article>
						<div className="thank-you">
							<h1>Bedankt voor het invullen van dit gespreksverslag.</h1>
						</div>
					</article>
				</section>
			</main>
		</Fragment>
	)
}

const mapStateToProps = state => ({
	thankyouMessage: state.global.thankyouMessage,
})
const mapDispatchToProps = dispatch => ({
	setThankyou: (value) => dispatch(setThankyou(value)),

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ThankMessage))
