import React from 'react'
import { connect } from 'react-redux'

const Homeshape = () => {

	return (
		<div className="homeshape">
		</div>
	)

}

export default connect(null, null)(Homeshape)
