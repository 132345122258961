import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

const Logo = () => {

	return (
		<div className="logo">
			<NavLink to={'/overzicht'}>
				<img src="/img/logo.svg" alt="Alzheimer Nederland" />
			</NavLink>
		</div>
	)

}

export default connect(null, null)(Logo)
