import React, { useEffect} from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { attemptLogin } from '../actions/user-actions'
import Logo from '../components/Logo'
import FormOneStop from '../components/FormOneStop'
import Homeshape from '../components/Homeshape'

const Home = ({ attemptLogin, isUserAuthenticated }) => {
	const params = useParams()
	const history = useHistory()

	useEffect(() => {
		if (params.hash) attemptLogin(params.hash)
	}, [])
	
	useEffect(() => {
		if (isUserAuthenticated) history.push('/overzicht')
	}, [isUserAuthenticated])

	return (
		<React.Fragment>
			<Homeshape />
			<main className="main_login">
				<Logo/>

				<section className="login">
					<article>
						<h2>Gespreksverslag DementieLijn</h2>

						<FormOneStop />
					</article>
				</section>
			</main>
		</React.Fragment>
	)
}

const mapStateToProps = state => ({
	isUserAuthenticated: state.user.isUserAuthenticated
})

const mapDispatchToProps = dispatch => ({
	attemptLogin: hash => dispatch(attemptLogin(hash))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
