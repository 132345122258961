import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { loadProfile } from '../actions/user-actions'
import {withRouter} from 'react-router-dom'

const Whois = ({ profile, loadProfile }) => {

	useEffect(() => {
		loadProfile(profile)
	}, [])
	return (
		<div className="whois">
			<h5>Ingelogd als<br />
				{profile &&
					<Fragment>
						<strong>{profile.user}</strong>	
					</Fragment>
				}
			</h5>
		</div>
	)
}

const mapStateToProps = state => ({
	profile: state.user.profile
})
const mapDispatchToProps = dispatch => ({
	loadProfile: () => dispatch(loadProfile())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Whois))