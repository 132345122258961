import React from 'react'
import ReactDOM from 'react-dom'
import './assets/sass/app.scss'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import Store from './store'
import MainRouter from './main-router.js'

ReactDOM.render(
	<Provider store={Store}>
		<Router>
			<MainRouter />
		</Router>
	</Provider>,
	document.getElementById('root')
)
