import React, { useState, useEffect, useCallback } from 'react'
// import { connect } from 'react-redux'
// import { getReport, getConversations } from '../actions/global-actions'
import { Link } from 'react-router-dom'
import Logo from '../components/Logo'
import Navigation from '../components/Navigation'
import Headershape from '../components/Headershape'
import projectSettings from '../projectsettings'

const Overview = (
	// { conversations, getConversations, areConversationsLoading }
) => {
	const [order, setOrder] = useState('desc')
	const [page, setPage] = useState(new URLSearchParams(window.location.search).get('page') ?? 1)
	const [totalPages, setTotalPages] = useState()
	const [pagedConversations, setPagedConversations] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchConversationsPage = useCallback(async (page, order) => {
		const response = await fetch(`${projectSettings.url}/api/conversations?order=${order}&page=${page}`)
		return await response.json()
	}, [])

	useEffect(() => {
		(async () => {
			setIsLoading(true)

			const convPage = await fetchConversationsPage(page, order)

			setPagedConversations(convPage.page)
			setTotalPages(convPage.totalPages)

			setIsLoading(false)
		})() 
	}, [order, page])

	const formatDate = date => {
		date = new Date(date.replace(/ /g, 'T'))
		return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
	}

	return (
		<React.Fragment>
			<Headershape />
			<main>
				<section className="page overview-page">
					<aside>
						<Logo />

						<Navigation active="start" />
					</aside>
					<article>
						<div className="overview-page-settings">
							<h1 className="title">Overzicht</h1>

							<div className="sort-button">
								<p>Sorteren op:</p>

								<select 
									onChange={(e) => setOrder(e.target.value)}
									name="sort" 
									id="sort"
								>
									<option value="desc">Aflopend</option>
									<option value="asc">Oplopend </option>
								</select>
							</div>
						</div>

						{!isLoading ? (
							<ul className="results">
								{pagedConversations.map((item, index) => (
									<Link to={`/gesprekverslag/${item.id}`}  className="results__item" key={index}>
										<img className="result__img" src="/img/report.png" alt="AH Report icon" />
										<b className="result__id">{item.id}:</b>
										<b className="result__body">{formatDate(item.date.date)}</b>
										<p className="result__title">{item.name}</p>
									</Link>
								))}
							</ul>
						) : (
							<div className="loader-container">
								<div className="loader"></div>
							</div>
						)}

						<div className='pager__container'>
							{[...Array(totalPages)].map((_, i) => {
								const p = i + 1
								return (
									<span 
										onClick={() => setPage(p)}
										key={p}
										style={p === page ? { 
											// 'textDecoration': p === page ? 'underline' : 'none'
											'backgroundColor': '#ffd800',
											'color': '#222'
										} : undefined} 
									>{p}</span>
								)
							})}
						</div>
					</article>
				</section>
			</main>
		</React.Fragment>
	)
}

// const mapStateToProps = state => ({
// 	conversations: state.global.conversations,
// 	areConversationsLoading: state.global.areConversationsLoading
// })
// const mapDispatchToProps = dispatch => ({
// 	getConversations: () => dispatch(getConversations()),
// 	getReport: id => dispatch(getReport(id)),
// })

// export default connect(mapStateToProps, mapDispatchToProps)(Overview)

export default Overview

