import React from 'react'
import { connect } from 'react-redux'

const Headershape = () => {

	return (
		<div className="headershape" style={{
			backgroundImage: 'url(/img/headershape.svg)'
		}}>
			<h3>DementieLijn</h3>
		</div>
	)

}

export default connect(null, null)(Headershape)
