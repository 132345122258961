/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useCallback, useEffect, useState } from 'react'
import classnames from 'classnames'
import DatePicker from 'react-date-picker'
import projectSettings from '../projectsettings'

const FormField = ({ input, id, label, disabled, type, placeholder, select, value, focus, classes, options, hidden, meta: { error, pristine, touched } }) => (
	<div className={classnames('row', {
		'error': error && touched,
		'large': type === 'textarea',
		'hidden': hidden
	})}>
		{label &&
            <label htmlFor={id}>{label}</label>
		}
		{type === 'textarea' &&
		<textarea
			{...input}
			id={id}
			placeholder={placeholder}
			disabled={disabled}
			autoFocus={focus}
			className={classnames(classes)}
		/>
		}
		{type === 'checkbox' &&
            <input
            	{...input}
            	id={id}
            	type={type}
            />
		}
		{(
			type === 'text' ||
            type === 'submit' ||
            type === 'number' ||
            type === 'email' ||
            type === 'password' ||
            type === 'hidden'
		) &&
            <input
            	{...input}
            	id={id}
            	placeholder={placeholder}
            	disabled={disabled}
            	type={type}
            	autoFocus={focus}
            	className={classnames(classes)}
            	autoComplete="off"
            	value={value !== null ? value : ''}

            />
		}
		{type === 'select' &&
            <select
            	{...input}
            	id={id}
            	options={options}
            	className={classnames(classes)}
            />
		}
	</div>
)

const SelectInput = ({ input, id, label, onChange, type, classes, options, hidden, meta }) => (
	<div className={classnames('row', {
		'error': (meta.error || meta.submitError) && meta.touched,
		'large': type === 'textarea',
		'hidden': hidden
	})}>
		{label &&
            <label htmlFor={id}>{label}</label>
		}
		<select
			{...input}
			id={id}
			className={classnames(classes)}
			onChange={(e) => {
				input.onChange(e) //final-form's onChange
				if (onChange) { //props.onChange
					onChange(e)
				}
			}}
		>
			{options.map((x, index) => {
				return (
					<option key={index} value={x.value}>{x.text}</option>
				)
			})}
		</select>
		{(meta.error || meta.submitError) && meta.touched && (
			<p className={'error'}>{meta.error || meta.submitError}</p>
		)}
	</div>
)

const RadioInput = ({ input, label, checked, onChange }) => (
	<div className="row row-radio">
		<label
			onClick={(e) => {
				input.onChange(e.target.value)
				if (onChange) onChange()
			}}>
			<input
				{...input}
				type="radio"
				checked={checked}
			/>
			<span>{label}</span>
		</label>
	</div>
)
const CheckboxInput = ({ input, label, initialChecked, onChange }) => {
	const [checked, setChecked] = useState(initialChecked)
	
	useEffect(() => {
		input.onChange(initialChecked)
	}, [])

	return (
		<label
			onClick={(e) => {
				input.onChange(e.target.value)
				setChecked(e.target.checked)
				if (onChange) onChange()
			}}>
			<input
				{...input}
				type="checkbox"
				checked={checked}
				value={label}
			/>
			<span>{label}</span>
		</label>
	)
}

const TextInput = ({ input, id, label, disabled, type, placeholder, focus, classes, onChange, hidden, meta }) => (
	<div id={(id === 'amount' ? 'row-amount' : '')} className={classnames('row', {
		'error': (meta.error || meta.submitError) && meta.touched,
		'large': type === 'textarea',
		'hidden': hidden
	})}>
		{label &&
            <label htmlFor={id}>{label}</label>
		}
		<input
			{...input}
			id={id}
			placeholder={placeholder}
			disabled={disabled}
			type="text"
			autoFocus={focus}
			className={classnames(classes)}
			autoComplete="off"
			onChange={(e) => {
				input.onChange(e) //final-form's onChange
				if (onChange) { //props.onChange
					onChange(e)
				}
			}} />
		{(meta.error || meta.submitError) && meta.touched && (
			<p className={'error'}>{meta.error || meta.submitError}</p>
		)}
	</div>
)

const TextAreaInput = ({ input, id, label, disabled, type, placeholder, focus, classes, onChange, hidden, meta }) => (
	<div id={(id === 'amount' ? 'row-amount' : '')} className={classnames('row', {
		'error': (meta.error || meta.submitError) && meta.touched,
		'large': type === 'textarea',
		'hidden': hidden
	})}>
		{label &&
        <label htmlFor={id}>{label}</label>
		}
		<textarea
			{...input}
			id={id}
			placeholder={placeholder}
			disabled={disabled}
			autoFocus={focus}
			className={classnames(classes)}
			autoComplete="off"
			onChange={(e) => {
				input.onChange(e) //final-form's onChange
				if (onChange) { //props.onChange
					onChange(e)
				}
			}} />
		{(meta.error || meta.submitError) && meta.touched && (
			<p className={'error'}>{meta.error || meta.submitError}</p>
		)}
	</div>
)

const DateInput = ({ input, id, label, disabled, type, placeholder, focus, classes, onChange, hidden, meta }) => (
	<div className={classnames('row', {
		'error': (meta.error || meta.submitError) && meta.touched,
		'large': type === 'textarea',
		'hidden': hidden
	})}>
		{label &&
            <label htmlFor={id}>{label}</label>
		}
		<DatePicker
			// onChange={(e) => {
			//     input.onChange(e); //final-form's onChange
			//     if (onChange) { //props.onChange
			//         onChange(e);
			//     }
			// }}
			{...input}
			dateFormat='yyyy-MM-dd'
			// required pattern='\d{4}-\d{2}-\d{2}'
		/>
		{(meta.error || meta.submitError) && meta.touched && (
			<p className="error">{meta.error || meta.submitError}</p>
		)}
	</div>
)

const EmailInput = ({ input, id, label, disabled, type, placeholder, focus, classes, onChange, hidden, meta }) => (
	<div className={classnames('row', {
		'error': (meta.error || meta.submitError) && meta.touched,
		'large': type === 'textarea',
		'hidden': hidden
	})}>
		{label &&
            <label htmlFor={id}>{label}</label>
		}
		<input
			{...input}
			id={id}
			placeholder={placeholder}
			disabled={disabled}
			type="text"
			autoFocus={focus}
			className={classnames(classes)}
			autoComplete="off"
			onChange={(e) => {
				input.onChange(e) //final-form's onChange
				if (onChange) { //props.onChange
					onChange(e)
				}
			}} />
		{(meta.error || meta.submitError) && meta.touched && (
			<p className={'error'}>{meta.error || meta.submitError}</p>
		)}
	</div>
)

const NumberInput = ({ input, id, label, disabled, type, placeholder, focus, classes, onChange, hidden, meta }) => {

	return (
		<div className={classnames('row', {
			'error': (meta.error || meta.submitError) && meta.touched,
			'large': type === 'textarea',
			'hidden': hidden
		})}>
			{label &&
            <label htmlFor={id}>{label}</label>
			}
			<input
				{...input}
				id={id}
				placeholder={placeholder}
				disabled={disabled}
				type="number"
				autoFocus={focus}
				className={classnames(classes)}
				autoComplete="off"
				onChange={(e) => {
					input.onChange(e) //final-form's onChange
					if (onChange) { //props.onChange
						onChange(e)
					}
				}}
				min="1"
				max="120"
				// onKeyDown={(e) => {
				//     if (e.key === '.' || e.key === '-' || e.key === 'e') {
				//         event.preventDefault()
				//         setShowMessage(true)
				//     } else {
				//         setShowMessage(false)
				//     }
				// }}
			/>
			{(meta.error || meta.submitError) && meta.touched && (
				<p className={'error'}>{meta.error || meta.submitError}</p>
			)}
		</div>
	)
}

const PasswordInput = ({ input, id, label, disabled, type, placeholder, focus, classes, onChange, hidden, meta }) => (
	<div className={classnames('row', {
		'error': (meta.error || meta.submitError) && meta.touched,
		'large': type === 'textarea',
		'hidden': hidden
	})}>
		{label &&
            <label htmlFor={id}>{label}</label>
		}
		<input
			{...input}
			id={id}
			placeholder={placeholder}
			disabled={disabled}
			type="password"
			autoFocus={focus}
			className={classnames(classes)}
			autoComplete="off"
			onChange={(e) => {
				input.onChange(e) //final-form's onChange
				if (onChange) { //props.onChange
					onChange(e)
				}
			}} />
		{(meta.error || meta.submitError) && meta.touched && (
			<p className={'error'}>{meta.error || meta.submitError}</p>
		)}
	</div>
)

const HiddenInput = ({ input, id, label, disabled, type, placeholder, select, value, focus, onChange, classes }) => (

	<input
		{...input}
		id={id}
		type="text"
		autoFocus={focus}
		value={value !== null ? value : ''}
		onChange={(e) => {
			input.onChange(e) //final-form's onChange
			if (onChange) { //props.onChange
				onChange(e)
			}
		}}
	/>
)


const SubmitInput = ({ input, id, disabled, type, placeholder, select, value, focus, classes, onChange, options, hidden, meta }) => (
	<input
		{...input}
		id={id}
		disabled={disabled}
		type="submit"
		className={classnames(classes)}
		onChange={(e) => {
			input.onChange(e) //final-form's onChange
			if (onChange) { //props.onChange
				onChange(e)
			}
		}}
	/>
)

const ContactInput = ({ input, onChange = (value) => {}, hidden, meta }) => {
	const [cmsOptions, setCmsOptions] = useState([])

	useEffect(() => {
		(async () => {
			const response = await fetch(`${projectSettings.url}/api/get-contact-options`)
			const json = await response.json()
			
			setCmsOptions(json.options)
		})()
	}, [])

	const onChangeRadio = useCallback((e) => {
		input.onChange(e)
		onChange(e)
	}, [])

	const inputName = `contact_input_${input.name}`

	return (
		<div className={classnames('row', {
			'error': (meta.error || meta.submitError) && meta.touched,
			'hidden': hidden
		})}>
			<table className='contact-input__container'>
				<tbody>
					{cmsOptions.map((option, i) => {
						return (
							<tr key={option.id}>
								<td style={{ minWidth: 50 }}>
									{option.name}
								</td>
								<td>
									{option.queueCodePhone && (
										<div className="radio-cell">
											<label htmlFor={`${inputName}_phone_${i}`}>Telefoon</label>
											<input id={`${inputName}_phone_${i}`} type="radio" name={inputName} onChange={() => onChangeRadio(option.queueCodePhone)} />
										</div>
									)}
								</td>
								<td>
									{option.queueCodeEmail && (
										<div className="radio-cell">
											<label htmlFor={`${inputName}_email_${i}`}>Email</label>
											<input id={`${inputName}_email_${i}`} type="radio" name={inputName} onChange={() => onChangeRadio(option.queueCodeEmail)} />
										</div>
									)}
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
			{(meta.error || meta.submitError) && meta.touched && (
				<p className={'error'}>{meta.error || meta.submitError}</p>
			)} 
		</div>
	)
}


export { FormField, SelectInput, TextInput, TextAreaInput, SubmitInput, HiddenInput, EmailInput, NumberInput, PasswordInput, RadioInput, DateInput, CheckboxInput, ContactInput }
