import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, isUserAuthenticated, ...rest  }) => (
	<Route {...rest} render={(props) => (
		isUserAuthenticated
			? <Component {...props} />
			: <Redirect to='/' />
	)} />
)

const mapStateToProps = state => ({
	isUserAuthenticated: state.user.isUserAuthenticated
})

export default connect(mapStateToProps, null)(PrivateRoute)