import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { attemptLocalstorageLogin } from './actions/user-actions'
import PrivateRoute from './components/PrivateRoute'
import Loginpage from './pages/login-page'
import Overview from './pages/overview'
import CallForm from './pages/callreport'
import ThankYou from './pages/thank_you'


const MainRouter = ({ location, attemptLocalstorageLogin }) => {
	useEffect(() => {
		attemptLocalstorageLogin()
	}, [])

	return (
		<Switch location={location}>
			<Route exact path={'/'} component={Loginpage} />
			<Route exact path={'/login/:hash'} component={Loginpage} />
			<PrivateRoute path={'/overzicht'} component={Overview} />
			<PrivateRoute exact path={'/gesprekverslag'} component={CallForm} />
			<PrivateRoute path={'/gesprekverslag/:id'} component={CallForm} />
			<PrivateRoute exact path={'/bedankt'} component={ThankYou}/>
		</Switch>
	)
}

const mapStateToProps = state => ({
	global: state.global
})

const mapDispatchToProps = dispatch => ({
	attemptLocalstorageLogin: () => dispatch(attemptLocalstorageLogin())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainRouter))
