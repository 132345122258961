import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { logout } from '../actions/user-actions'
import WhoIs from './Whois'

const Navigation = ({ active = false, logout }) => {

	const submitLogout = () => {
		logout()
	}

	return (
		<nav>
			<NavLink to={'/overzicht'} >Overzicht</NavLink>
			<NavLink exact to={'/gesprekverslag'} >Nieuw Gesprekverslag</NavLink>
			<WhoIs />
			<NavLink exact to={'/'} onClick={(e) => { submitLogout(); e.preventDefault()}}>Uitloggen</NavLink>
			
		</nav>
	)
}

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(logout()),
})

export default connect(null, mapDispatchToProps)(Navigation)
