import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { getSubstances, getReport, setReport, getSpoken, getAcquired } from '../actions/global-actions'
import { loadProfile } from '../actions/user-actions'
import FormCallReport from '../components/FormCallReport'
import Logo from '../components/Logo'
import Navigation from '../components/Navigation'
import Headershape from '../components/Headershape'
import InfoCallreport from '../components/info-callreport'
import { useParams, Redirect } from 'react-router-dom'

const CallReport = ({ areSubstancesLoading, getSubstances, getReport, report, setReport, thankyouMessage, isReportLoading, getSpoken, getAcquired, loadProfile, isSubmitLoading }) => {
	const { id } = useParams()

	useEffect(() => {
		if (!id) loadProfile()
		if (id) getReport(id)
		
		getSubstances()
		getSpoken()
		getAcquired()

		return () => setReport([])
	}, [id])

	if (thankyouMessage) {
		return (
			<Redirect to={'/bedankt'} />
		)
	}

	return (
		<React.Fragment>
			<Headershape />
			<main>
				<section className="page callreport-form-page">
					<aside>
						<Logo />

						<Navigation active="start" />
					</aside>

					<article>
						<div className="row">
							<InfoCallreport />

	
							<div className="callreport__title">
								<h1>Gesprekverslag 
									{report.id && 
										<Fragment>
											: {report.id }
										</Fragment>
									}
								</h1>
							</div>
							
							{!areSubstancesLoading && ((id && report.id) || !id) && 
								<FormCallReport id={id} initialValues={report} />
							}
							{(areSubstancesLoading || isReportLoading) &&
								<div className="loader-container">
									<div className="loader"></div>
								</div>
							}
							{(isSubmitLoading) &&
							<div className="loader-container">
								<div className="loader"></div>
							</div>
							}
						</div>
					</article>
				</section>
			</main>
		</React.Fragment>
	)
}

const mapStateToProps = state => ({
	areSubstancesLoading: state.global.areSubstancesLoading,
	report: state.global.report,
	thankyouMessage: state.global.thankyouMessage,
	isReportLoading: state.global.isReportLoading,
	isSubmitLoading: state.global.isSubmitLoading
})

const mapDispatchToProps = dispatch => ({
	loadProfile: () => dispatch(loadProfile()),
	getSpoken: () => dispatch(getSpoken()),
	getSubstances: () => dispatch(getSubstances()),
	getAcquired: () => dispatch(getAcquired()),
	getReport: id => dispatch(getReport(id)),
	setReport: value => dispatch(setReport(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(CallReport)
